import { Softwares } from "enum/Softwares.enum";
import axios from "../axios";
import { Size } from "enum/Size.enum";
import { Subscription } from "screens/Billing/dto/subscription.dto";
import { Invoice } from "screens/Invoice/dto/invoice.dto";
//import authAxiosFactory from "../axios-auth";

export const createSubscription = async (
  account_iid: string,
  software: Softwares,
  size: Size,
  quantity: number
): Promise<Subscription> => {
  const req: any = await axios({
    method: `put`,
    url: `/v1/accounts/${account_iid}/payments/subscription`,
    data: {
      items: [{
        software,
        size,
        quantity
      }]
    }
  });
  return req?.data?.data;
};


export const getAllInvoices = async (
  account_iid: string,
  limit: number
): Promise<Invoice[]> => {
  const req: any = await axios({
    method: `get`,
    url: `/v1/accounts/${account_iid}/payments/invoices`,
    params: {
      limit
    }
  });
  return req?.data?.data?.invoices;
};