import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { getUserData } from "utils/user";
import { useState } from "react";

const drawerWidth: number = 0;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface TopbarProps {
  toggleDrawer: Function;
  open: boolean;
  isLogged: boolean;
  setDarktheme: (inp: boolean) => void;
}

const Topbar = ({
  toggleDrawer,
  open = true,
  isLogged = false,
  setDarktheme,
}: TopbarProps) => {
  const [userInfo] = useState(getUserData());
  const navigate = useNavigate();
  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => toggleDrawer()}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          <Link style={{ color: "inherit", textDecoration: "none" }} to="/">
            LOGO dctl-host
          </Link>
        </Typography>
        {isLogged ? (
          <Link style={{ color: "inherit", textDecoration: "none" }} to={"/user"}>
            {userInfo?.email}
          </Link>
        ) : (
          <Button
            onClick={() => {
              navigate("/signin");
            }}
            sx={{ color: "#fff" }}
            startIcon={<LoginIcon />}
          >
            Sign in
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
