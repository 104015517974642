import {
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestLogin } from "services/apis/auth";

const RequestLogin = ({ goToOtpCheck }: { goToOtpCheck: Function }) => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setEmail(event.target.value);
  };
  const loginHandler = async () => {
    setLoading(true);
    requestLogin(email).then(() => {
      goToOtpCheck("otpCheck", email);
    });
  };

  return (
    <>
      <Typography sx={{ mt: 3 }} component="h1" variant="h5">
        Sign in / Register
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Enter your email address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={handleEmailChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              loginHandler();
              e.preventDefault()
            }
          }}
        />

        <Button
          onClick={loginHandler}
          fullWidth
          variant="contained"
          disabled={loading}
          sx={{ mt: 3, mb: 2 }}
        >
          {loading ? <CircularProgress size={15} color="primary" /> : null}
          Enter
        </Button>

        <Button
          onClick={() => navigate("/")}
          fullWidth
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
        >
          Home
        </Button>
      </Box>
    </>
  );
};

export default RequestLogin;
