const PleromaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg4485"
    width="20.00002"
    height="35"
    viewBox="0 0 209.00002 334"
  >
    <metadata id="metadata4491"></metadata>
    <defs id="defs4489" />

    <g id="g4612" transform="translate(-152,-89)">
      <path
        id="path4495"
        d="M 235,89 V 423 H 152 V 115 l 26,-26 z"
      />
      <circle r="26" cx="178" cy="115" id="path4497" />
      <circle r="26" cx="335" cy="230" id="path4497-0" />
      <path
        id="path4516"
        d="M 277,256 V 89 l 84,3e-6 L 361.00002,230 335,256 Z"
      />
      <circle r="26" cx="335" cy="397" id="path4497-0-6" />
      <path
        id="path4516-5"
        d="m 277,423 v -83 h 84 l 2e-5,57 L 335,423 Z"
      />
    </g>
  </svg>
);

export default PleromaIcon;
