import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useEffect, useState } from "react";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { getCommunitySizes } from "services/apis/public";
import { CommunitySize } from "dto/community-size.dto";
import { Size } from "enum/Size.enum";

const constants = {
  SINGLE_USER: <PersonIcon fontSize="large" />,
  COMMUNITY_SMALL: <GroupIcon fontSize="large" />,
  COMMUNITY_LARGE: <GroupAddIcon fontSize="large" />,
}

const notedStyle = {
  boxShadow: 5,
  backgroundColor: "#ececec"
}

const defaultStyle = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  border: 2,
  borderRadius: 2,
  borderColor: "#bbb",
  mx: 1,
  my: 1,
  py: 1,
}

const Item = ({
  itemInput,
  callBack
}: {
  itemInput: ItemType,
  callBack: Function
}) => {
  const [style, setStyle] = useState(defaultStyle);
  useEffect(() => {
    if (itemInput.isDefault) {
      setStyle({ ...defaultStyle, ...notedStyle })
    }
    else {
      setStyle({ ...defaultStyle })
    }
  }, [itemInput.isDefault])
  return (
    <Grid item xs={12} md={4} >
      <Box
        sx={style}
        component="div"
        onClick={() => {
          callBack(itemInput.slug);
        }}
      >
        <Box>
          <Typography variant="h6"
            sx={{
              display: "flex"
            }}
          >
            <Box sx={{ mx: 1 }} >{itemInput.icon}</Box>
            {itemInput.title}
            {
              itemInput.isDefault ? <DoneOutlineIcon sx={{ mx: 1 }} /> : null
            }
          </Typography>
        </Box>
        <Box sx={{ my: 1 }}>{itemInput.description !== "" ? itemInput.description : 'One user'}</Box>
      </Box>
    </Grid>
  );
};

class ItemType {
  slug!: string;
  title!: string;
  description!: string;
  icon!: any;
  isDefault!: boolean;
}

const mapResult2Item = (input: CommunitySize[]): ItemType[] => {
  const itemTmp: ItemType[] = []
  for (const i in input) {
    const tmp: ItemType = {
      ...input[i],
      icon: constants[input[i].slug],
      isDefault: input[i].slug === Size.COMMUNITY_SMALL
    };
    itemTmp.push(tmp);
  }
  return itemTmp;
}

const CommunitySizeComponent = ({ changePriceCallBack }: { changePriceCallBack: Function }) => {
  const [items, setItems] = useState<ItemType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const setDefault = (slug: string) => {
    changePriceCallBack(slug);
    const tmpItem = [...items];
    tmpItem.map(item => {
      item.isDefault = false;
      if (item.slug === slug) {
        item.isDefault = true;
      }
      return item;
    })
    setItems(tmpItem);
  }

  useEffect(() => {
    setLoading(true);
    getCommunitySizes().then(res => {
      setItems(mapResult2Item(res));
      setLoading(false);
    })
  }, []);
  return (
    <Box
      component={Paper}
      sx={{
        borderRadius: 5,
        mt: 1,
        p: 2,
      }}
    >
      <Typography variant="h5">2- Choose a community size </Typography>

      <Grid container >
        {loading ? <CircularProgress sx={{ m: 3 }} /> : null}
        {items.map((item: any, i: any) => (
          <Item
            key={i}
            itemInput={item}
            callBack={setDefault}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default CommunitySizeComponent;
