import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { logout } from "../utils/user";
import { showError } from "../utils/swlAlert";

const headers: any = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  headers,
  withCredentials: true,
  params: { __cors_domain: window.location.origin },
};

const apiClient: AxiosInstance = axios.create(config);

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (error.response && error.response.status === 401) {
    logout();
    window.location.href = "/signin";
  }
  if (error.response && error.response.status === 403 ) {
    logout();
    window.location.href = "/signin";
  }
  if (error.response && error.response.status >= 400) {
    showError(error?.response?.data);
    return Promise.reject(error);
  }
  return Promise.reject(error);
}

apiClient.interceptors.response.use(onResponse, onResponseError);

export default apiClient;
