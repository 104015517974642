import { CommunitySize } from "dto/community-size.dto";
import { Price } from "dto/price.dto";
import axios from "../axios";
import { Software } from "dto/software-dto";
import { SoftwareType } from "dto/SoftwareType.dto";

export const getSoftwares = async (
  type: string | undefined = undefined
): Promise<Software[]> => {
  const req: any = await axios({
    method: "get",
    url: "public/v1/softwares",
    params: {
      type,
    },
  });
  return req?.data?.data?.softwares;
};

export const getCommunitySizes = async (): Promise<CommunitySize[]> => {
  const req: any = await axios({
    method: "get",
    url: "public/v1/community_sizes",
  });
  return req?.data?.data?.sizes;
};

export const calcPrice = async (software: string, size: string): Promise<Price> => {
  const req: any = await axios({
    method: "get",
    url: "public/v1/pricing",
    params: {
      software,
      size
    }
  });
  return req?.data?.data?.price;
}

export const getTypes = async (): Promise<SoftwareType[]> => {
  const req: any = await axios({
    method: "get",
    url: "public/v1/types",
  });
  return req?.data?.data?.types;
}