import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { useEffect, useState } from 'react';

const ElementProvider = ({ clientSecret }: { clientSecret: string }) => {
    const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
    const options = {
        clientSecret,
    };

    useEffect(() => {
        loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string).then(res => {
            setStripePromise(res);
        });
    }, [])

    return (
        <>
            {
                stripePromise ?
                    (<Elements stripe={stripePromise} options={options}>
                        <CheckoutForm />
                    </Elements>) : null
            }
        </>
    );
};

export default ElementProvider;