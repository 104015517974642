import {
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useContext, useState } from "react";
import { verifyMagicCode } from "services/apis/auth";
import { setIsLoggedin } from "utils/auth";
import { saveUserData } from "utils/user";
import { AuthContext } from "components/AppContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showError } from "utils/swlAlert";

const CheckOtp = ({
  email,
  goToRequestLogin,
}: {
  email: string;
  goToRequestLogin: Function;
}) => {
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const software = searchParams.get("sf");
  const communitySize = searchParams.get("sz");

  const handleCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setCode(event.target.value);
  };
  const varifyHandler = async () => {
    setLoading(true);
    verifyMagicCode(email, code).then(async () => {
      setIsLoggedin('true');
      saveUserData({ email });
      authContext.login(true);
      navigate(`/billing/${communitySize || 'COMMUNITY_SMALL'}/${software || 'MASTODON'}/1`);
    }).catch(e => {
      showError("Magic code is not correct");
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <Typography sx={{ mt: 3 }} component="h1" variant="h5">
        Enter Magic Code:
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Magic Code"
          name="code"
          autoComplete="null"
          autoFocus
          value={code}
          onChange={handleCodeChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              varifyHandler();
              e.preventDefault()
            }
          }}
        />

        <Button
          onClick={varifyHandler}
          fullWidth
          variant="contained"
          disabled={loading}
          sx={{ mt: 3, mb: 2 }}
        >
          {loading ? <CircularProgress size={15} color="primary" /> : null}
          Verify
        </Button>

        <Button
          onClick={() => goToRequestLogin("login", email)}
          fullWidth
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
        >
          Try another email address
        </Button>
      </Box>
    </>
  );
};

export default CheckOtp;
