import {
  Twitter,
  // Instagram,
  // Facebook,
  // LinkedIn,
  // Pinterest,
  // YouTube,
} from "@mui/icons-material";
import { Box, Button, Divider, Paper, Typography, CircularProgress } from "@mui/material";
import { SoftwareType } from "dto/SoftwareType.dto";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Carousel from "react-material-ui-carousel";
import { getTypes } from "services/apis/public";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

// let items = [
//   {
//     name: "Twitter Style",
//     description:
//       "Social Networks come in many different flavors. This category is for Micro Blogging — where you share concise written messages, that can include images and video clips.",
//     icon: <Twitter fontSize="large" />,
//   },
//   {
//     name: "Instagram Style",
//     description:
//       "Social Networks come in many different flavors. This category is for Photo Logging — where you share photos and other images.",
//     icon: <Instagram fontSize="large" />,
//   },
//   {
//     name: "Facebook Style",
//     description:
//       "Social Networks come in many different flavors. This category is for Online Directories — where you share your personal information, photos galleries, etc.",
//     icon: <Facebook fontSize="large" />,
//   },
//   {
//     name: "YouTube Style",
//     description:
//       "Social Networks come in many different flavors. This category is for Video Platforms — where you create a channel, and share videos.",
//     icon: <YouTube fontSize="large" />,
//   },
//   {
//     name: "LinkedIn Style",
//     description:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     icon: <LinkedIn fontSize="large" />,
//   },
//   {
//     name: "Pinterest Style",
//     description:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     icon: <Pinterest fontSize="large" />,
//   },
//   {
//     name: "LinkedIn Style",
//     description:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     icon: <LinkedIn fontSize="large" />,
//   },
// ];

const Item = ({
  name,
  description,
  slug,
  icon,
  width,
  isDefault,
  callBack
}: {
  name: string;
  description: string;
  slug: string;
  icon: any;
  width: string;
  isDefault: boolean;
  callBack: Function
}) => {
  const defautStyle = {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    border: 2,
    borderRadius: 2,
    borderColor: "#bbb",
    marginLeft: 1,
    my: 1,
    p: 1,
    width,
  }
  const notedStyle = {
    boxShadow: 5,
    backgroundColor: "#ececec"
  }
  return (
    <Box
      sx={
        isDefault ? { ...defautStyle, ...notedStyle } : { ...defautStyle }
      }
    >
      <Box sx={{ my: 1, display: "flex" }}>
        {icon}
        <Typography variant="h6">{name}</Typography>
      </Box>
      <Box sx={{ fontSize: 13, minHeight: 80 }}>{description}</Box>
      <Button variant="contained" color="info" onClick={() => callBack(isDefault ? null : slug)} >
        Choose
        {
          isDefault ? <DoneOutlineIcon sx={{ mx: 1 }} /> : null
        }
      </Button>
    </Box>
  );
};

const GetCaroaselItem = ({ inputArray, filterSoftwars }: { inputArray: SoftwareType[], filterSoftwars: Function }) => {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {inputArray.map((item: SoftwareType, i: number) => (
        <Item
          key={i}
          name={item.title}
          description={item.description}
          slug={item.slug}
          //icon={item.icon}
          icon={<Twitter fontSize="large" />}
          width="32%"
          callBack={filterSoftwars}
          isDefault={item.isDefault || false}
        />
      ))}
    </Box>
  );
};

const mapObjectToParts = (inputArray: any) => {
  const out: any[][] = [];
  let tmp: any[] = [];
  inputArray.map((item: any, i: any) => {
    if ((i + 1) % 3 !== 0) {
      tmp.push(item);
    } else {
      tmp.push(item);
      out.push(tmp);
      tmp = [];
    }
    return i;
  });
  if (tmp.length) {
    out.push(tmp);
  }
  return out;
};

const TypeCaroasel = ({ filterSoftwars }: { filterSoftwars: Function }) => {
  const [types, setTypes] = useState<SoftwareType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const setDefault = (slug: string | undefined) => {
    filterSoftwars(slug);
    const tmpItem = [...types];
    tmpItem.map(item => {
      item.isDefault = false;
      if (item.slug === slug) {
        item.isDefault = true;
      }
      return item;
    })
    setTypes(tmpItem);
  }

  useEffect(() => {
    setLoading(true);
    getTypes().then(res => {
      setTypes(res);
      setLoading(false);
    })
  }, [])
  return (
    <Box
      component={Paper}
      sx={{
        borderRadius: 5,
        mt: -10,
        p: 2,
      }}
    >
      <Typography variant="h5">1- Choose an experiance type</Typography>
      <Divider sx={{ my: 1 }}></Divider>
      {loading ? <CircularProgress sx={{ m: 3 }} /> : null}
      <MobileView>
        <Carousel
          navButtonsProps={{
            style: {
              backgroundColor: "cornflowerblue",
              borderRadius: "50%",
              opacity: 0.6,
            },
          }}
          autoPlay={false}
          animation="slide"
          navButtonsAlwaysVisible={true}
        >
          {types.map((item: SoftwareType, i: any) => (
            <Item
              key={i}
              name={item.title}
              description={item.description}
              slug={item.slug}
              icon={<Twitter fontSize="large" />}
              width="auto"
              callBack={setDefault}
              isDefault={item.isDefault || false}
            />
          ))}
        </Carousel>
      </MobileView>

      <BrowserView>
        <Carousel
          navButtonsProps={{
            style: {
              backgroundColor: "cornflowerblue",
              borderRadius: "50%",
              opacity: 0.6,
            },
          }}
          autoPlay={false}
          animation="slide"
          navButtonsAlwaysVisible={true}
        >
          {mapObjectToParts(types).map((items: SoftwareType[], i: number) => {
            return <GetCaroaselItem inputArray={items} key={i} filterSoftwars={setDefault} />;
          })}
        </Carousel>
      </BrowserView>
    </Box>
  );
};

export default TypeCaroasel;
