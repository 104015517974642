import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    styled,
    tableCellClasses
} from "@mui/material";
import { Invoice, Line } from "../dto/invoice.dto";
import Cent2Dollar from "components/Cent2Dollar";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#404040",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const SingleInvoice = ({ invoice }: { invoice: Invoice }) => {
    return <>
        <Grid container spacing={3} sx={{ my: 2 }} >
            <Grid item xs={12} md={3} >
                Number: {invoice.number}
            </Grid>
            <Grid item xs={12} md={3} >
                Staus: {invoice.status}
            </Grid>
            <Grid item xs={12} md={3} >
                Total: <Cent2Dollar amount={invoice.total} />
            </Grid>
            <Grid item xs={12} md={3} >
                AmountPaid: <Cent2Dollar amount={invoice.amountPaid} />
            </Grid>
        </Grid>
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">Index</StyledTableCell>
                        <StyledTableCell align="left">name</StyledTableCell>
                        <StyledTableCell align="left">description</StyledTableCell>
                        <StyledTableCell align="left">amount</StyledTableCell>
                        <StyledTableCell align="left">currency</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoice.lines.map((element: Line, index: number) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell align="left">
                                {index + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {element.nickname}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {element.description}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <Cent2Dollar amount={element.amount} />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {element.currency}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export default SingleInvoice;