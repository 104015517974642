import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from './listItems';
import Typography from '@mui/material/Typography';
import { isMobile } from 'react-device-detect'
import { useEffect, useState } from 'react';
import { getUserData } from 'utils/user';
const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

interface SidebarProps {
    toggleDrawer: Function;
    open: boolean;
}

const Sidebar = ({ toggleDrawer, open = false }: SidebarProps) => {
    const [userInfo] = useState(getUserData());
    useEffect(() => {
        if (isMobile) {
            toggleDrawer(false);
        }

    }, [toggleDrawer]);

    return (<Drawer variant="permanent" open={open}>
        <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: [1],
            }}
        >

            <Typography variant='body2' sx={{
                color: 'info.main',
                p: [1]
            }}
                onClick={() => toggleDrawer()}
            >
                {userInfo?.email}
            </Typography>

            <IconButton onClick={() => toggleDrawer()}>
                <ChevronLeftIcon />
            </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav" >
            <MainListItems />
            <Divider sx={{ my: 1 }} />
        </List>
    </Drawer >)
}

export default Sidebar;