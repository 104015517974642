
import { Container, Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";


const PaymentStatusSreen = () => {
    const [searchParams] = useSearchParams();
    // const payment_intent = searchParams.get("payment_intent");
    // const payment_intent_client_secret = searchParams.get("payment_intent_client_secret");
    const redirectStatus = searchParams.get("redirect_status");
    return <Container maxWidth={false} sx={{ mt: 1, mb: 4 }}>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography component="h1" variant="h4" sx={{ py: 1 }} color="green" >
                Payment status {redirectStatus}
            </Typography>
        </Box>

    </Container>
}

export default PaymentStatusSreen;