import { Size } from "enum/Size.enum";
import { Softwares } from "enum/Softwares.enum";
import { useEffect, useState } from "react";
import { getAllAcounts } from "services/apis/account";
import { createSubscription } from "services/apis/payments";
import ElementProvider from "./components/ElementProvider";
import { Container, Box, Typography, CircularProgress, Grid, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Button } from "@mui/material";
import { showSuccess } from "utils/swlAlert";
import { useParams } from "react-router-dom";
import { calcPrice } from "services/apis/public";
import Cent2Dollar from "components/Cent2Dollar";

const BillingScreen = () => {
    const [clientSecret, setClientSecret] = useState<string>("");
    const [accountIid, setAccountIid] = useState<string>("");
    const [communitySize, setCommunitySize] = useState<Size>(Size.COMMUNITY_SMALL);
    const [software, setSoftware] = useState<Softwares>(Softwares.MASTODON);
    const [quantity, setQuantity] = useState<string>("1");
    const [loading, setLoading] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(0);
    const [loadingAmount, setLoadingAmount] = useState<boolean>(false);
    const { cs, sft, qnt } = useParams();


    useEffect(() => {
        getAllAcounts().then(res => {
            setAccountIid(res[0].iid);
        })
    }, [])

    useEffect(() => {
        if (Object.values(Size).includes(cs as Size)) {
            setCommunitySize(cs as Size);
        }

        if (Object.values(Softwares).includes(sft as Softwares)) {
            setSoftware(sft as Softwares);
        }

        if (+(qnt as string) >= 1 && +(qnt as string) <= 5) {
            setQuantity(qnt as string);
        }
    }, [cs, sft, qnt])

    useEffect(() => {
        setLoadingAmount(true);
        calcPrice(software, communitySize).then(res => {
            setAmount(res.amount * +quantity);
            setLoadingAmount(false);
        })
    }, [quantity, software, communitySize])

    const handleStripe = () => {
        setLoading(true);
        createSubscription(accountIid, software, communitySize, +quantity).then(res => {
            if (res.msg) {
                showSuccess(res.msg)
            }
            if (res.clientSecret) {
                setClientSecret(res.clientSecret);
            }
            setLoading(false);
        });
    }

    const handleChangeSize = (
        e: SelectChangeEvent<Size>
    ) => {
        setCommunitySize(e.target.value as Size);
    };


    const handleChangeSofteware = (
        e: SelectChangeEvent<Softwares>
    ) => {
        setSoftware(e.target.value as Softwares);
    };

    const handleChangeQuantity = (
        e: SelectChangeEvent<string>
    ) => {
        setQuantity(e.target.value);
    };

    return <Container maxWidth={false} sx={{ mt: 1, mb: 4 }}>
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Typography component="h1" variant="h4" sx={{ py: 1 }}>
                Billing
            </Typography>

        </Box>
        <Grid container spacing={1} >
            <Grid item xs={12} md={5} >
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="exchange-label">
                        Community size
                    </InputLabel>
                    <Select
                        value={communitySize}
                        input={<OutlinedInput label="Community size" />}
                        onChange={handleChangeSize}
                        variant="outlined"
                        displayEmpty
                        disabled={loading || clientSecret !== ""}
                    >
                        <MenuItem value={Size.SINGLE_USER}>
                            <em>SINGLE USER</em>
                        </MenuItem>
                        <MenuItem value={Size.COMMUNITY_SMALL}>
                            <em>COMMUNITY SMALL</em>
                        </MenuItem>
                        <MenuItem value={Size.COMMUNITY_LARGE}>
                            <em>COMMUNITY LARGE</em>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={5} >
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="exchange-label">
                        Software
                    </InputLabel>
                    <Select
                        value={software}
                        input={<OutlinedInput label="Software" />}
                        onChange={handleChangeSofteware}
                        variant="outlined"
                        displayEmpty
                        disabled={loading || clientSecret !== ""}
                    >
                        {
                            (Object.keys(Softwares) as Array<keyof typeof Softwares>).map((key) =>
                                <MenuItem value={key} key={key}>
                                    <em>{key}</em>
                                </MenuItem>
                            )

                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={2} >
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="exchange-label">
                        Quantity
                    </InputLabel>
                    <Select
                        value={quantity}
                        input={<OutlinedInput label="Quantity" />}
                        onChange={handleChangeQuantity}
                        variant="outlined"
                        displayEmpty
                        disabled={loading || clientSecret !== ""}
                    >
                        {
                            ['1', '2', ' 3', '4', '5'].map((key) =>
                                <MenuItem value={key} key={key}>
                                    <em>{key}</em>
                                </MenuItem>
                            )

                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "end" }}>
                <Typography
                    variant="h6"
                >
                    {amount === 0 || loadingAmount ? <CircularProgress color="success" /> : <Cent2Dollar amount={amount} />} / month
                </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    endIcon={loading ? <CircularProgress size={15} color="inherit" /> : null}
                    onClick={handleStripe}
                    disabled={loading || clientSecret !== ""}
                >
                    Purchase
                </Button>
            </Grid>
        </Grid>
        <Box
            sx={{
                p: 1
            }}
        >
            {clientSecret === "" ? null : <ElementProvider clientSecret={clientSecret} />}
        </Box>
    </Container>
}
export default BillingScreen;