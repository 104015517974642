import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Paper, Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from "react";
import { getAllUsers } from "services/apis/user";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useParams } from "react-router-dom";
import { User } from "./dto/user.dto";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#404040",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));



const UsersScreen = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { iid } = useParams();

  useEffect(() => {
    setLoading(true);
    if (iid) {
      getAllUsers(iid).then(res => {
        setUsers(res);
        setLoading(false);
      });
    }
  }, [iid]);
  return (
    <Container maxWidth={false} sx={{ mt: 1, mb: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" sx={{ py: 1 }}>
          Users
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="info"
            startIcon={<AddCircleOutlineIcon />}

          >
            New
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Index</StyledTableCell>
              <StyledTableCell align="left">Id</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Enable</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((element: User, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="left">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell align="left">{element.iid}</StyledTableCell>
                <StyledTableCell align="left">{element.emailAddress}</StyledTableCell>
                <StyledTableCell align="left">
                  {element.isDisabled ? <DisabledByDefaultIcon /> : <CheckCircleIcon color="success" />}
                </StyledTableCell>

                <StyledTableCell align="left">
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        {loading && <CircularProgress sx={{ m: 2 }} />}
      </TableContainer>
    </Container>
  );
};

export default UsersScreen;
