import { useEffect, useState } from "react";
import { getAllAcounts } from "services/apis/account";
import { getAllInvoices } from "services/apis/payments";
import { Invoice } from "./dto/invoice.dto";
import { Container, Box, Typography, CircularProgress } from "@mui/material";
import SingleInvoice from "./components/SingleInvoice";


const InvoiceSreen = () => {
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        getAllAcounts().then(res => {
            const accountIid = res[0].iid;
            getAllInvoices(accountIid, 10).then(invoices => {
                setInvoices(invoices);
                setLoading(false);
            })
        })

    }, [])
    return <Container maxWidth={false} sx={{ mt: 1, mb: 4 }}>
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Typography component="h1" variant="h4" sx={{ py: 1 }}>
                Invoices
            </Typography>
        </Box>
        {loading ? <CircularProgress /> : null}
        {
            invoices.map((item: Invoice, index: number) => <SingleInvoice invoice={item} key={index} />)
        }
    </Container>
}

export default InvoiceSreen;