import { Box, Grid, } from "@mui/material";

import CommunitySize from "./components/CommunitySize";
import Header from "./components/Header";
import Price from "./components/Price";
import TypeCaroasel from "./components/TypeCaroasel";
import SoftwareSelect from "./components/SoftwareSelect";
import { useState } from "react";
import { Softwares } from "enum/Softwares.enum";
import { Size } from "enum/Size.enum";

const HomeScreen = () => {
  const [selectedSize, setSelectedSize] = useState<Size>(Size.COMMUNITY_SMALL);
  const [selectedSoftware, setSelectedSoftware] = useState<Softwares>(Softwares.MASTODON);
  const [selcetdType, setSelectedType] = useState<string | undefined>(undefined);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Header />
        </Grid>

        <Grid item xs={0} md={1} lg={1}></Grid>
        <Grid item xs={12} md={10} lg={10}>
          <TypeCaroasel filterSoftwars={setSelectedType} />
        </Grid>
        <Grid item xs={0} md={1} lg={1}></Grid>

        <Grid item xs={0} md={1} lg={1}></Grid>
        <Grid item xs={12} md={10} lg={10}>
          <CommunitySize changePriceCallBack={setSelectedSize} />
        </Grid>
        <Grid item xs={0} md={1} lg={1}></Grid>

        <Grid item xs={0} md={1} lg={1}></Grid>
        <Grid item xs={12} md={10} lg={10}>
          <SoftwareSelect changePriceCallBack={setSelectedSoftware} type={selcetdType} />
        </Grid>
        <Grid item xs={0} md={1} lg={1}></Grid>

        <Grid item xs={0} md={1} lg={1}></Grid>
        <Grid item xs={12} md={10} lg={10}>
          <Price selectedSize={selectedSize} selectedSoftware={selectedSoftware} />
        </Grid>
        <Grid item xs={0} md={1} lg={1}></Grid>
      </Grid>

      <Box
        sx={{
          backgroundColor: "#666",
          minHeight: 200,
          mt: 2,
          textAlign: "center",
          p: 1,
          color: "white",
        }}
      >
        Footer
      </Box>
    </div>
  );
};

export default HomeScreen;
