import { useState, useContext } from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { enUS } from "@mui/material/locale";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Sidebar from "./sildebar";
import { Outlet } from "react-router-dom";
import { getDarktheme } from "../utils/utils";
import Topbar from "./topbar";
import { Toolbar } from "@mui/material";
import { AuthContext } from "components/AppContext";

const MainUser = () => {
  const [open, setOpen] = useState(true);
  // const [isDarkTheme, setIsDarkTheme] = useState(getDarktheme());
  const [isDarkTheme] = useState(getDarktheme());
  const authContext = useContext(AuthContext);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // const setDarkthemeForAll = (isDarkTheme: boolean) => {
  //   setIsDarkTheme(isDarkTheme);
  //   setDarktheme(isDarkTheme ? "true" : "false");
  // };

  let theme = createTheme(
    {
      typography: {
        fontSize: 16,
      },
      palette: {
        mode: isDarkTheme ? "dark" : "light",
      },
    },
    enUS
  );

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Topbar
          open={open}
          toggleDrawer={toggleDrawer}
          // setDarktheme={setDarkthemeForAll}
          setDarktheme={() => { }}
          isLogged={authContext.isLoggedIn}
        />

        <Sidebar open={open} toggleDrawer={toggleDrawer} />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : "#121721",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MainUser;
