import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { enUS } from "@mui/material/locale";
import bg from "assets/img/login-ng.jpeg";
import { getDarktheme } from "utils/utils";
import RequestLogin from "./components/RequestLogin";
import CheckOtp from "./components/CheckOtp";

function Copyright(props: any) {
  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copy right © "}
        <Link color="inherit" href="#">
          dctl-host
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    </>
  );
}

const SigninScreen = () => {
  const [pageState, setPageState] = useState<"login" | "otpCheck">("login");
  const [email, setEmail] = useState<string>("");

  const goToStatus = (pageState: "login" | "otpCheck", email: string) => {
    setPageState(pageState);
    setEmail(email);
  };

  const theme = createTheme(
    {
      typography: {},
      palette: {
        mode: getDarktheme() ? "dark" : "light",
      },
    },
    enUS
  );

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              mx: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                width: "50%",
                margin: "0 auto",
              }}
              alt="login"
              src="/logo512.png"
            />

            {pageState === "login" ? (
              <RequestLogin goToOtpCheck={goToStatus} />
            ) : (
              <CheckOtp goToRequestLogin={goToStatus} email={email} />
            )}

            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(" + bg + ")",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : "#121721",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default SigninScreen;
