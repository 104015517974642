import axios from "../axios";
//import authAxiosFactory from "../axios-auth";

export const requestLogin = async (email_address: string): Promise<unknown> => {
  const req: any = await axios({
    method: `AUTHPOKE`,
    url: `/public/v1/users`,
    params: {
      email_address,
    },
  });
  return req?.data;
};

export const verifyMagicCode = async (
  email_address: string,
  code: string
): Promise<unknown> => {
  const req: any = await axios({
    method: `get`,
    url: `/public/v1/magiccode/${code}`,
    params: {
      email_address,
    },
  });
  return req?.data;
};

export const logoutUser = async (): Promise<unknown> => {
  const req: any = await axios({
    method: `LOGOUT`,
    url: `/v1/logout`,
  });
  return req?.data;
};
