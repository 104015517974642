import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useNavigate } from "react-router-dom"
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DomainIcon from '@mui/icons-material/Domain';
import ReceiptIcon from '@mui/icons-material/Receipt';

export const MainListItems = () => {
    let navigate = useNavigate();
    return <>
        <ListItemButton onClick={() => navigate('/user')}>
            <ListItemIcon>
                <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
        </ListItemButton>

        <ListItemButton onClick={() => navigate('/instances')}>
            <ListItemIcon>
                <DomainIcon />
            </ListItemIcon>
            <ListItemText primary="Instances" />
        </ListItemButton>

        <ListItemButton onClick={() => navigate('/billing/COMMUNITY_SMALL/MASTODON/1')}>
            <ListItemIcon>
                <PaymentsIcon />
            </ListItemIcon>
            <ListItemText primary="Billing" />
        </ListItemButton>

        <ListItemButton onClick={() => navigate('/invoices')}>
            <ListItemIcon>
                <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Invoices" />
        </ListItemButton>

        <ListItemButton onClick={() => navigate('/signout')}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Exit" />
        </ListItemButton>
    </>
};