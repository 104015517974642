import { useState, createContext } from 'react';
import { getIsLoggedin } from '../utils/auth';

interface contextProps {
    isLoggedIn: boolean;
    login: Function;
}
export const AuthContext = createContext<contextProps>({
    isLoggedIn: false,
    login: () => { },
});

const AuthContextProvider = (props: any) => {
    const isLoggedin = !!getIsLoggedin();
    const [isSignin, setIsSignin] = useState(isLoggedin);
    return (
        <AuthContext.Provider value={{
            isLoggedIn: isSignin,
            login: (inp: boolean) => setIsSignin(inp),
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;