import { Box } from "@mui/material";
import bg from "../../../assets/img/bg.jpeg";

const Header = () => {
  return (
    <Box
      sx={{
        backgroundImage: "url(" + bg + ")",
        height: 400,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        component="div"
        color={"#fff"}
        sx={{
          fontSize: 45,
          fontWeight: "bold",
        }}
      >
        Create Your Own Fedivers Instance
      </Box>
    </Box>
  );
};

export default Header;
