import { useContext, useEffect } from "react";
import { AuthContext } from "../../components/AppContext";
import { logout } from "../../utils/user";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "services/apis/auth";

const SignoutScreen = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const logoutHandler = () => {
    logoutUser().then((res) => {
      logout();
      authContext.login(false);
      navigate("/signin");
    });
  };
  useEffect(() => {
    logoutHandler();
  }, []);
  return (
    <div>
      <h3>Sigout</h3>
    </div>
  );
};

export default SignoutScreen;
