import { Account } from "screens/Accounts/dto/account.dto";
import axios from "../axios";
//import authAxiosFactory from "../axios-auth";

export const getAllAcounts = async (): Promise<Account[]> => {
  const req: any = await axios({
    method: `get`,
    url: `/v1/accounts`,
  });
  return req?.data?.data?.accounts;
};

export const getAccount = async (accountIid: string): Promise<unknown> => {
  const req: any = await axios({
    method: `get`,
    url: `/v1/accounts/${accountIid}`,
  });
  return req?.data;
};
