import {
  Box,
  Paper,
  Typography,
  Divider,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  CircularProgress
} from "@mui/material";
import MastodonIcon from "components/svg/MastodonIcon";
import CalckeyIcon from "components/svg/CalckeyIcon";
import MisskeyIcon from "components/svg/MisskeyIcon";
import PleromaIcon from "components/svg/PleromaIcon";
import { getSoftwares } from "services/apis/public";
import { useEffect, useState } from "react";
import { Software } from "dto/software-dto";
import { Softwares } from "enum/Softwares.enum";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';


const softwareIcons = {
  CALCKEY: <CalckeyIcon />,
  MASTODON: <MastodonIcon />,
  MISSKEY: <MisskeyIcon />,
  PLEROMA: <PleromaIcon />,
  PIXELFED: <PleromaIcon />,
  PEERTUBE: <PleromaIcon />,
  LEMMY: <PleromaIcon />,
}

const notedStyle = {
  boxShadow: 5,
  backgroundColor: "#ececec"
}

const defaultStyle = {
  minHeight: 340
}

const Item = ({
  input,
  callBack
}: {
  input: MySoftware,
  callBack: Function
}) => {
  const [style, setStyle] = useState(defaultStyle);

  useEffect(() => {
    if (input.isDefault) {
      setStyle({ ...defaultStyle, ...notedStyle })
    }
    else {
      setStyle({ ...defaultStyle })
    }
  }, [input.isDefault])
  return (
    <Grid item xs={12} md={4} xl={3}>
      <Card sx={style}>
        <CardMedia
          sx={{ height: 140 }}
          image={require("../../../assets/img/mastodon-app.jpg")}
          title="green iguana"
        />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Box>{input.icon}</Box>
            <Typography
              sx={{ marginLeft: 1 }}
              gutterBottom
              variant="h5"
              component="div"
            >
              {input.title}
            </Typography>
          </Box>

          <Box sx={{ fontSize: 12 }} color="text.secondary">
            {input.description}
          </Box>
        </CardContent>
        <CardActions >
          <Button
            size="small"
            onClick={() => {
              callBack(input.slug);
            }}
          >
            {
              input.isDefault ?
                (<Box sx={{ display: "flex", alignItems: "center" }} >
                  <Box sx={{ mt: 1 }}>selected</Box>
                  <DoneOutlineIcon fontSize="small" sx={{ mx: 1 }} />
                </Box>) : "select"
            }
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

class MySoftware extends Software {
  isDefault!: boolean;
  icon: any;
}


const SoftwareSelect = ({ changePriceCallBack, type }: { changePriceCallBack: Function, type: string | undefined }) => {
  const [softwares, setSoftwares] = useState<MySoftware[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const mapResult2SoftwareItem = (input: Software[]): MySoftware[] => {
    const itemTmp: MySoftware[] = []
    for (const i in input) {
      const tmp: MySoftware = {
        ...input[i],
        icon: softwareIcons[input[i].slug as Softwares] ? softwareIcons[input[i].slug as Softwares] : null,
        isDefault: input[i].slug === "MASTODON"
      };
      itemTmp.push(tmp);
    }
    return itemTmp;
  }

  const setDefault = (slug: string) => {
    changePriceCallBack(slug);
    const tmpItem = [...softwares];
    tmpItem.map(item => {
      item.isDefault = false;
      if (item.slug === slug) {
        item.isDefault = true;
      }
      return item;
    })
    setSoftwares(tmpItem);
  }

  useEffect(() => {
    setLoading(true);
    getSoftwares(type).then(res => {
      setSoftwares(mapResult2SoftwareItem(res));
      setLoading(false);
    })
  }, [type])
  return (
    <Box
      component={Paper}
      sx={{
        borderRadius: 5,
        mt: 2,
        p: 2,
      }}
    >
      <Typography variant="h5">3- Choose a software</Typography>
      <Divider sx={{ my: 1 }}></Divider>
      {loading ? <CircularProgress sx={{ m: 3 }} /> : null}
      <Grid container spacing={1}>
        {softwares.map((item, i) => (
          <Item
            input={item}
            callBack={setDefault}
            key={i}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default SoftwareSelect;
