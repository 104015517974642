import { useState, useContext } from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { enUS } from "@mui/material/locale";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { getDarktheme } from "../utils/utils";
import Topbar from "./topbar";
import { Toolbar } from "@mui/material";
import { AuthContext } from "components/AppContext";

const Main = () => {
  const [isDarkTheme] = useState(getDarktheme());
  const authContext = useContext(AuthContext);

  let theme = createTheme(
    {
      typography: {
        fontSize: 16,
      },
      palette: {
        mode: isDarkTheme ? "dark" : "light",
      },
    },
    enUS
  );

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Topbar
          open={true}
          toggleDrawer={() => false}
          setDarktheme={() => { }}
          isLogged={authContext.isLoggedIn}
        />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : "#121721",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Main;
