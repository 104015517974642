import { useContext, useEffect, useState } from "react";
import Main from "layout/main";
import MainUser from "userLayout/main";

import HomeScreen from "screens/Home";
import NoMatch from "../layout/404";

import SigninScreen from "screens/Signin";
import AccountsScreen from "screens/Accounts";
import UsersScreen from "screens/Users";
import BillingScreen from "screens/Billing";
import DomainScreen from "screens/Domain";
import InvoiceSreen from "screens/Invoice";
import SignoutScreen from "screens/Signout";
import PaymentStatusSreen from "screens/PaymentStatus";

import { Routes, Route } from "react-router-dom";
import { AuthContext } from "../components/AppContext";

const MainRouter = () => {
  const appContext = useContext(AuthContext);
  const [isLogged, setisLogged] = useState(false);
  useEffect(() => {
    setisLogged(appContext.isLoggedIn);
  }, [appContext.isLoggedIn]);
  return (
    <Routes>
      {isLogged ? (
        <Route path="/">
          <Route path="/signin" element={<SigninScreen />} />
          <Route element={<Main />}>
            <Route index element={<HomeScreen />} />
            <Route path="/signout" element={<SignoutScreen />} />
            <Route path="*" element={<NoMatch />} />
          </Route>

          <Route element={<MainUser />}>
            <Route path="/billing/:cs/:sft/:qnt" element={<BillingScreen />} />
            <Route path="/user" element={<AccountsScreen />} />
            <Route path="list/:iid" element={<UsersScreen />} />
            <Route path="/Instances" element={<DomainScreen />} />
            <Route path="/invoices" element={<InvoiceSreen />} />
            <Route path="/payment-status" element={<PaymentStatusSreen />} />
          </Route>
        </Route>
      ) : (
        <Route path="/">
          <Route path="/signin" element={<SigninScreen />} />
          <Route path="*" element={<SigninScreen />} />
          <Route path="/" element={<Main />}>
            <Route index element={<HomeScreen />} />
          </Route>
        </Route>
      )}
    </Routes>
  );
};
export default MainRouter;
