import { Typography, Box, Paper, Button, CircularProgress } from "@mui/material";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import ShopIcon from '@mui/icons-material/Shop';
import { BrowserView } from "react-device-detect";
import { useEffect, useState, useContext } from "react";
import { calcPrice } from "services/apis/public";
import CalckeyIcon from "components/svg/CalckeyIcon";
import MastodonIcon from "components/svg/MastodonIcon";
import MisskeyIcon from "components/svg/MisskeyIcon";
import PleromaIcon from "components/svg/PleromaIcon";
import { Softwares } from "enum/Softwares.enum";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Size } from "enum/Size.enum";
import Cent2Dollar from "components/Cent2Dollar";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "components/AppContext";

const softwareIcons = {
  CALCKEY: <CalckeyIcon />,
  MASTODON: <MastodonIcon />,
  MISSKEY: <MisskeyIcon />,
  PLEROMA: <PleromaIcon />,
  PIXELFED: <PleromaIcon />,
  PEERTUBE: <PleromaIcon />,
  LEMMY: <PleromaIcon />,
}

const sizeIcons = {
  SINGLE_USER: <PersonIcon fontSize="large" />,
  COMMUNITY_SMALL: <GroupIcon fontSize="large" />,
  COMMUNITY_LARGE: <GroupAddIcon fontSize="large" />,
}

const Price = ({ selectedSoftware, selectedSize }: { selectedSoftware: Softwares, selectedSize: Size }) => {
  const [amount, setAmount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const appContext = useContext(AuthContext);
  const navigat = useNavigate();

  useEffect(() => {
    setLoading(true);
    calcPrice(selectedSoftware, selectedSize).then(res => {
      setAmount(res.amount);
      setLoading(false);
    })
  }, [selectedSize, selectedSoftware]);
  return (
    <>
      <Box
        component={Paper}
        sx={{
          textAlign: "center",
          borderRadius: 5,
          mt: 2,
          p: 2,
          boxShadow: 5,
        }}
      >
        <Typography variant="h5">Price based on your choices</Typography>
        <Box
          sx={{
            fontSize: 40,
            fontWeight: "bold",
            color: "#0c6f0c",
            textShadow: 5,
          }}
        >
          {amount === 0 || loading ? <CircularProgress color="success" /> : <Cent2Dollar amount={amount} />} / month
        </Box>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          startIcon={<SubdirectoryArrowRightIcon />}
          endIcon={<SubdirectoryArrowLeftIcon />}
          onClick={() => {
            if (appContext.isLoggedIn) {
              navigat(`/billing/${selectedSize}/${selectedSoftware}/1`)
            }
            else {
              navigat(`/signin?sz=${selectedSize}&sf=${selectedSoftware}`)
            }
          }}
        >
          Create in Less than 2 minutes
        </Button>
      </Box>

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <Box sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 1
        }}>
          <BrowserView>
            <Box sx={{ mx: 1 }}>
              Price based on your choices
            </Box>
          </BrowserView>
          <Box>
            <Box
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#0c6f0c",
                textShadow: 5,
                mx: 1
              }}
            >
              {amount === 0 || loading ? <CircularProgress color="success" size={15} /> : (amount / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })} / month
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}
            >
              {softwareIcons[selectedSoftware]}
              {sizeIcons[selectedSize]}
            </Box>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<ShopIcon />}
            onClick={() => {
              if (appContext.isLoggedIn) {
                navigat(`/billing/${selectedSize}/${selectedSoftware}/1`)
              }
              else {
                navigat(`/signin?sz=${selectedSize}&sf=${selectedSoftware}`)
              }
            }}
          >
            Buy Now
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default Price;
