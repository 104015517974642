export const setIsLoggedin = (token: string) => {
    localStorage.setItem('isLoggedin', token);
}

export const getIsLoggedin = () => {
    return localStorage.getItem('isLoggedin');
}

export const removeIsLoggedin = () => {
    localStorage.removeItem('isLoggedin');
}